import{ apiServiceAWS } from './apiService';
import { NewProduct, ProductID, ProductUpdate } from './types';

export const getInventoryManagementProductsAndStockMetrics = () => {
    return apiServiceAWS.get('/inventorymanagement/productsandstock');
}

export const InventoryManagementUpdateProductFields = (productId: ProductID , updates: ProductUpdate) => {
    return apiServiceAWS.patch(`/inventorymanagement/updateproduct/${productId}`, updates);
}

export const InventoryManagementNewProduct = (productData: NewProduct) => {
    return apiServiceAWS.post('/inventorymanagement/addproduct', productData);
}

// not in use but possibly in the future
export const InventoryManagementDeleteProduct = (productId: ProductID) => {
    return apiServiceAWS.delete(`/inventorymanagement/deleteproduct/${productId}`);
}