import{ apiServiceAWS } from './apiService';
import type { 
    AddCustomerData,
    AddressID,
    CustomerName,
    DeliveryAddressData,
    IsActivePayload,
    PricingAgreementData,
    ProductID,
    ResetPasswordData, 
    UpdateCustomerInvoiceMandatoryData, 
    UpdateDeliveryAddressData,
    UpdatePricingAgreementData
} from './types'

export const getCustomerMetrics = () => {
    return apiServiceAWS.get('/crm/customerdata');
};

export const getPricingAgreementsForCustomer = (customerName: CustomerName) => {
    return apiServiceAWS.get(`/crm/pricingagreementforcustomer/${customerName}`);
};

export const addPricingAgreement = (data: PricingAgreementData) => {
    return apiServiceAWS.post('/crm/addpricingagreement', data);
};

export const addCustomer = (data: AddCustomerData) => {
    return apiServiceAWS.post('/crm/addcustomer', data);
};

export const resetPassword = (data: ResetPasswordData) => {
    return apiServiceAWS.post('/crm/resetpassword', data);
};

export const addDeliveryAddress = (data: DeliveryAddressData) => {
    return apiServiceAWS.post('/crm/add-delivery-address', data)
};

// currently not in use but possibly in the future
export const updateDeliveryAddress = (addressID: AddressID, data: UpdateDeliveryAddressData) => {
    return apiServiceAWS.put(`/crm/update-delivery-address/${addressID}`, data)
};

export const updateIsActiveDeliveryAddress = (addressID: AddressID, data: IsActivePayload) => {
    return apiServiceAWS.put(`/crm/update-delivery-address-status/${addressID}`, data)
};

export const updatePricingAgreement = (customerName: CustomerName, productID: ProductID, data: UpdatePricingAgreementData) => {
    return apiServiceAWS.put(`/crm/updatepricingagreement/${customerName}/${productID}`, data);
};

export const updateCustomerInvoiceMandatory = (customerName: CustomerName, data: UpdateCustomerInvoiceMandatoryData) => {
    return apiServiceAWS.put(`/crm/updatecustomerinvoicemandatory/${customerName}`, data);
};