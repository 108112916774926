import React, { useState} from "react";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nl from 'date-fns/locale/nl';
import {format} from 'date-fns';
import { searchOrders } from "../../../api/salesAPI.ts";
import './orders.css';
import Tooltip from "../../shared/Tooltip.tsx";

const SearchOrders = () => {
    const [searchParams, setSearchParams] = useState({
        customerName: "",
        orderDateFrom: null,
        orderDateTo: null,
        deliveryDatePreferredFrom: null,
        deliveryDatePreferredTo: null,
        deliveryDateActualFrom: null,
        deliveryDateActualTo: null,
        invoiceNumber: "",
        customerInvoice: "",
        orderStatus: "",
        productName: "",
        quantity: "",
        comment: "",
        address: ""
    });
    const [orders, setOrders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(20);
    const [isSearching, setIsSearching] = useState(false);

    const totalPages = Math.ceil(orders.length / entriesPerPage);

    // pagination logic
    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    const currentEntries = orders.slice(indexOfFirstEntry, indexOfLastEntry);

    const paginate = pageNumber => setCurrentPage(pageNumber);
    const nextPage = () => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(orders.length / entriesPerPage)));
    const prevPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));
    console.log(currentEntries);

    const handleEntriesPerPageChange = (e) => {
        setEntriesPerPage(Number(e.target.value));
        setCurrentPage(1); // reset to first page when changing entries per page
    };

    const orderStatusOptions = [
        'nieuw',
        'bevestigd',
        'verzonden',
        'geannuleerd'
        ];

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setSearchParams(prev => ({
            ...prev,
            [name]: value
        }));
    };
    
    const handleDateChange = (date, name) => {
        setSearchParams(prev => ({
            ...prev,
            [name]: date
        }));
    };

    const handleSearch = async () => {
        setIsSearching(true);
        try {
            const formattedParams = {
                ...searchParams,
                orderDateFrom: searchParams.orderDateFrom ? format(searchParams.orderDateFrom, 'yyyy-MM-dd') : null,
                orderDateTo: searchParams.orderDateTo ? format(searchParams.orderDateTo, 'yyyy-MM-dd') : null,
                deliveryDatePreferredFrom: searchParams.deliveryDatePreferredFrom ? format(searchParams.deliveryDatePreferredFrom, 'yyyy-MM-dd') : null,
                deliveryDatePreferredTo: searchParams.deliveryDatePreferredTo ? format(searchParams.deliveryDatePreferredTo, 'yyyy-MM-dd') : null,
                deliveryDateActualFrom: searchParams.deliveryDateActualFrom ? format(searchParams.deliveryDateActualFrom, 'yyyy-MM-dd') : null,
                deliveryDateActualTo: searchParams.deliveryDateActualTo ? format(searchParams.deliveryDateActualTo, 'yyyy-MM-dd') : null
        };
        const response = await searchOrders(formattedParams);
        setOrders(response.data);
        } catch (error) {
            console.error("Error searching orders:", error);
        } finally {
            setIsSearching(false);
        }
    };

    const renderProductDetail = (detail) => (
        <div key={detail.ProductID} style={{padding: '5px 0'}}>
            <div><strong>{detail.ProductName}</strong></div>
            <div>{detail.SaleQuantity} cilinder(s)</div>
        </div>
    )

    return (
        <div>
            <h2>Zoek order</h2>
            <Tooltip text="Hier kun je terugzoeken in alle orders met de filters die je hieronder instelt. Als je geen filter instelt krijg je alle orders te zien." />
            <div>
                <label htmlFor="entriesPerPage">Aantal orders per pagina:</label>
                <select
                    name="entriesPerPage"
                    value={entriesPerPage}
                    onChange={handleEntriesPerPageChange}
                >
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>
                            <input
                                type="text"
                                name="customerName"
                                placeholder="Klantnaam"
                                value={searchParams.customerName}
                                onChange={handleInputChange}
                            />
                        </th>
                        <th>
                        <Datepicker
                            selected={searchParams.orderDateFrom}
                            onChange={(date) => handleDateChange(date, "orderDateFrom")}
                            locale={nl}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Besteldatum van"
                        />
                        <Datepicker
                            selected={searchParams.orderDateTo}
                            onChange={(date) => handleDateChange(date, "orderDateTo")}
                            locale={nl}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Besteldatum tot"
                        />
                        </th>
                        <th>
                        <Datepicker
                            selected={searchParams.deliveryDateActualFrom}
                            onChange={(date) => handleDateChange(date, "deliveryDateActualFrom")}
                            locale={nl}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Leverdatum van"
                        />
                        <Datepicker
                            selected={searchParams.deliveryDateActualTo}
                            onChange={(date) => handleDateChange(date, "deliveryDateActualTo")}
                            locale={nl}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Leverdatum tot"
                        />
                        </th>
                        <th>
                            <input
                                type="text"
                                name="invoiceNumber"
                                placeholder="Ordernummer"
                                value={searchParams.invoiceNumber}
                                onChange={handleInputChange}
                            />
                        </th>
                        <th>
                            <input
                                type="text"
                                name="customerInvoice"
                                placeholder="Ordernummer klant"
                                value={searchParams.customerInvoice}
                                onChange={handleInputChange}
                            />
                        </th>
                        <th>
                            <select
                                name="orderStatus"
                                value={searchParams.orderStatus}
                                onChange={handleInputChange}
                            >
                                <option value="">Selecteer status</option>
                                {orderStatusOptions?.map((status) => (
                                    <option key={status} value={status}>
                                        {status}
                                    </option>
                                ))}
                            </select>
                        </th>
                        <th>
                            <input
                                type="text"
                                name="productName"
                                placeholder="Product"
                                value={searchParams.productName}
                                onChange={handleInputChange}
                            />
                            <input
                                type="text"
                                name="quantity"
                                placeholder="Hoeveelheid"
                                value={searchParams.quantity}
                                onChange={handleInputChange}
                            />
                        </th>
                        <th>
                            <input
                                type="text"
                                name="comment"
                                placeholder="Opmerking"
                                value={searchParams.comment}
                                onChange={handleInputChange}
                            />
                        </th>
                        <th>
                            <input
                                type="text"
                                name="address"
                                placeholder="Adres"
                                value={searchParams.address}
                                onChange={handleInputChange}
                            />
                        </th>
                        <th>
                            {isSearching ? (
                                <button disabled>
                                    <div className="spinner"></div> Zoeken...
                                </button>
                            ) : (
                                <button onClick={handleSearch}>Zoek orders</button>
                            )}
                        </th>
                    </tr>
                    <tr>
                        <th>Klant</th>
                        <th>Besteldatum</th>
                        <th>Leverdatum</th>
                        <th>Ordernummer</th>
                        <th>Ordernummer klant</th>
                        <th>Orderstatus</th>
                        <th>Product en hoeveelheid</th>
                        <th>Opmerking</th>
                        <th>Adres</th>
                    </tr>
                </thead>
                <tbody>
                    {currentEntries && currentEntries?.map((order) => (
                        <tr key={order.OrderID}>
                            <td className="bordered-cell">{order.CustomerName}</td>
                            <td className="bordered-cell">
                                {order.OrderDate 
                                    ? new Date(order.OrderDate).toLocaleDateString('nl-NL')
                                    : ''}
                            </td>
                            <td className="bordered-cell">
                                {order.ActualDeliveryDate 
                                    ? new Date(order.ActualDeliveryDate).toLocaleDateString('nl-NL')
                                    : ''}
                            </td>
                            <td className="bordered-cell">{order.InvoiceNumber}</td>
                            <td className="bordered-cell">{order.CustomerInvoice}</td>
                            <td className="bordered-cell">{order.OrderStatus}</td>
                            <td className="bordered-cell">
                                {order.OrderDetails?.map(renderProductDetail)}
                            </td>
                            <td className="bordered-cell">{order.Comment}</td>
                            <td className="bordered-cell">{order.DeliveryCity} {order.DeliveryZipCode} {order.DeliveryStreetName} {order.DeliveryHouseNumber}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination">
                <button onClick={prevPage} disabled={currentPage <= 1}>Vorige</button>
                {Array.from({ length: totalPages }, (_, i) => (
                    <button 
                        key={i + 1}
                        className={currentPage === i + 1 ? "active" : ""}
                        onClick={() => setCurrentPage(i + 1)}
                    >
                        {i + 1}
                    </button>
                ))}
                <button onClick={nextPage} disabled={currentPage >= totalPages}>Volgende</button>
            </div>
        </div>
    );
};

export default SearchOrders;