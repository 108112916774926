import React, { useEffect, useState } from "react";
import { getNewOrders, confirmOrder, cancelOrder, updateActualDeliveryDate, submitSale } from "../../../api/salesAPI.ts";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nl from 'date-fns/locale/nl';
import {format} from 'date-fns';
import Tooltip from "../../shared/Tooltip.tsx";
import './orders.css';

const OpenOrders = () => {
    const [orders, setOrders] = useState([]);
    const [isFetchingOrders, setIsFetchingOrders] = useState(false);
    const [isUpdatingDeliveryDate, setIsUpdatingDeliveryDate] = useState(false);
    const [confirmingOrder, setConfirmingOrder] = useState(false);
    const [isSendingOrder, setIsSendingOrder] = useState(false);
    const [isCancelingOrder, setIsCancelingOrder] = useState(false);

    useEffect(() => {
        const fetchOrders = async () => {
            setIsFetchingOrders(true);
            try {
                const response = await getNewOrders();
                setOrders(response.data)
            } catch (error) {
                console.error("Error fetching orders:", error);
            } finally {
                setIsFetchingOrders(false);
            };
        };

        fetchOrders();
    }, []);

    const handleButtonConfirmOrder = async (orderID) => {
        setConfirmingOrder(prev => ({ ...prev, [orderID]: true }));
        try {
            await confirmOrder(orderID);
            // refresh orders after confirmation
            const response = await getNewOrders();
            setOrders(response.data);
        } catch (error) {
            console.error("Error confirming order:", error);
        } finally {
            setConfirmingOrder(prev => ({ ...prev, [orderID]: false }));
        };
    };

    const handleButtonCancelOrder = async (orderID) => {
        setIsCancelingOrder(prev => ({ ...prev, [orderID]: true }));
        try {
            await cancelOrder(orderID);
            // refresh orders after confirmation
            const response = await getNewOrders();
            setOrders(response.data);
        } catch (error) {
            console.error("Error confirming order:", error);
        } finally {
            setIsCancelingOrder(prev => ({ ...prev, [orderID]: false }));
        };
    };

    const handleActualDeliveryDateChange = async (orderID, date) => {
        setIsUpdatingDeliveryDate(prev => ({...prev, [orderID]: true}));
        try {
            //Format the date to dd-mm-yyyy
            const formattedDate = format(date, 'yyyy-MM-dd');
            await updateActualDeliveryDate(orderID, {ActualDeliveryDate: formattedDate});

            //Refresh orders adter updating actual delivery date
            const response = await getNewOrders();
            setOrders(response.data);
        } catch (error) {
            console.error('Error setting actual delivery date:', error);
        } finally {
            setIsUpdatingDeliveryDate(prev => ({...prev, [orderID]: false}));
        };
    };

    const handleSendOrder = async (orderID) => {
        setIsSendingOrder(prev => ({...prev, [orderID]: true}));
        try {
            // Call the API to submit the sale and set the orderstatus to verzonden
            await submitSale({OrderID: orderID});

            // Refresh orders after submitting the sale
            const response = await getNewOrders();
            setOrders(response.data);
        } catch (error) {
            console.error('Error sending order:', error);
        } finally {
            setIsSendingOrder(prev => ({...prev, [orderID]: false}));
        };
    };

    const renderProductDetail = (detail) => (
        <div key={detail.ProductID} style={{padding: '5px 0'}}>
            <div><strong>{detail.ProductName}</strong></div>
            <div>{detail.SaleQuantity} cilinder(s)</div>
        </div>
    )

    return (
        <div>
            <h2>Orders</h2>
            <Tooltip text="Hier vind je alle openstaande orders. De orders hebben de status nieuw of bevestigd. 
                    Zodra je een order verzendt of annuleert verdwijnt deze uit dit overzicht." />
            {isFetchingOrders ? (
                <div className="spinner"></div>
                ) : (
            <table>
                <thead>
                    <tr>
                        <th>Klant
                        </th>
                        <th>Besteldatum
                        <Tooltip text="De datum waarop de klant de bestelling heeft gedaan" />
                        </th>
                        <th>Gewenste leverdatum
                        <Tooltip text="De gewenste datum waarop de klant de bestelling wilt ontvangen" />
                        </th>
                        <th>Werkelijke leverdatum
                        <Tooltip text="Hier kun je de werkelijke datum aanklikken wanneer de bestelling geleverd kan worden. Een datum in het verleden is niet mogelijk. 
                        Zodra je een werkelijke leverdatum hebt aangeklikt kun je de order bevestigen." />
                        </th>
                        <th>Ordernummer
                        <Tooltip text="Automatisch gegenereerd ordernummer door het bestelportaal. Dit ordernummer ziet de klant ook in de email" />
                        </th>
                        <th>Ordernummer klant
                        <Tooltip text="Als de klant een inkoopordernummer heeft ingevuld staat deze hieronder" />
                        </th>
                        <th>Orderstatus
                        <Tooltip text={`Hier vind je de status van de order. Een order kan een van de volgende statussen hebben:\n\n
                            • Nieuw: De order is nieuw en de leverdatum moet nog bevestigd worden.
                            \n• Bevestigd: De order is bevestigd en de werkelijke leverdatum is ingevuld, de order kan worden verzonden of geannuleerd.
                            \n• Verzonden: De order is verzonden naar de klant, zodra de orderstatus verandert naar verzonden is de order niet meer zichtbaar in dit overzicht.
                            \n• Geannuleerd: De order is geannuleerd en wordt niet meer verzonden. De order is niet meer zichtbaar in dit overzicht.`} />
                        </th>
                        <th>Product en hoeveelheid
                        <Tooltip text="De productnaam met daaronder het aantal bestelde cilinders" />
                        </th>
                        <th>Opmerking
                        <Tooltip text="Door de klant gemaakte opmerking bij de bestelling" />
                        </th>
                        <th>Adres
                        <Tooltip text="Het doorgegeven adres waar de bestelling kan worden bezorgd" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {orders?.map((order) => (
                        <tr key={order.OrderID}>
                            <td className="bordered-cell">{order.CustomerName}</td>
                            <td className="bordered-cell">{order.OrderDate ? new Date(order.OrderDate).toLocaleDateString('nl-NL'): ''}</td>
                            <td className="bordered-cell">{order.PreferredDeliveryDate ? new Date(order.PreferredDeliveryDate).toLocaleDateString('nl-NL'): ''}</td>
                            <td className="bordered-cell">
                                {order.ActualDeliveryDate ? (
                                    new Date(order.ActualDeliveryDate).toLocaleDateString('nl-NL')
                                ) : (
                                    isUpdatingDeliveryDate[order.OrderID] ? (
                                        <div className="spinner"></div> // Show spinner when updating
                                    ) : (
                                        <Datepicker
                                            selected={order.ActualDeliveryDate ? new Date(order.ActualDeliveryDate) : null}
                                            onChange={(date) => handleActualDeliveryDateChange(order.OrderID, date)}
                                            minDate={new Date()} // prevent selection of past dates
                                            filterDate={(date) => {
                                                const today = new Date();
                                                return (date.getDay() !== 0 && date.getDay() !== 6)
                                            }}
                                            placeholderText="Selecteer een datum"
                                            locale={nl}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    )
                                )}
                            </td>
                            <td className="bordered-cell">{order.InvoiceNumber}</td>
                            <td className="bordered-cell">{order.CustomerInvoice}</td>
                            <td className="bordered-cell">{order.OrderStatus}</td>
                            <td className="bordered-cell">
                                {order.OrderDetails?.map(renderProductDetail)}
                            </td>
                            <td className="bordered-cell">{order.Comment}</td>
                            <td className="bordered-cell">{order.DeliveryCity} {order.DeliveryZipCode} {order.DeliveryStreetName} {order.DeliveryHouseNumber}</td>
                            {/* <td>
                                <button>
                                    Order aanpassen
                                </button>
                            </td> */}
                            <td>
                                {order.OrderStatus === 'nieuw' && order.ActualDeliveryDate && (
                                    confirmingOrder[order.OrderID] ? (
                                        <button disabled>
                                            <div className="spinner"></div> Bevestigen...
                                        </button>
                                    ) : (
                                        <button onClick={() => handleButtonConfirmOrder(order.OrderID)}>
                                            Bevestig order
                                            <Tooltip text="Als de werkelijke leverdatum bekend is kan de order worden bevestigd. Op dit moment is de orderstatus
                                                    in het bestelportaal niet zichtbaar en krijgt de klant geen aparte email van de bevestiging." />
                                        </button>
                                    )
                                )}
                            </td>
                            <td>
                                {order.OrderStatus === 'bevestigd' && order.ActualDeliveryDate && (
                                    isSendingOrder[order.OrderID] ? (
                                        <button disabled>
                                            <div className="spinner"></div> Verzenden...
                                        </button>
                                    ) : (
                                        <button onClick={() => handleSendOrder(order.OrderID)}>
                                            Verzend order
                                            <Tooltip text="Als de order onderweg is naar de klant kan de order worden verzonden. 
                                                De orderstatus verandert naar verzonden en de order is niet meer zichtbaar in dit overzicht. 
                                                De klant ontvangt op dit moment nog geen email met de verzendbevestiging." />
                                        </button>
                                        
                                    )
                                )}
                            </td>
                            <td>
                                {isCancelingOrder[order.OrderID] ? (
                                    <button disabled>
                                        <div className="spinner"></div> Annuleren...
                                    </button>
                                ) : (
                                    <button onClick={() => handleButtonCancelOrder(order.OrderID)}>
                                        Annuleer order
                                        <Tooltip text="Als de order niet doorgaat kan deze worden geannuleerd. 
                                        De orderstatus verandert naar geannuleerd en de order is niet meer zichtbaar in dit overzicht. 
                                        De klant ontvangt op dit moment nog geen email met de annuleringsbevestiging." />
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )}
        </div>
    );
};

export default OpenOrders;