import React, { useState, useEffect } from 'react';
import DeliveryAddressManager from './DeliveryAddressManager';
import AddCustomerForm from './AddCustomerForm';
import Tooltip from '../shared/Tooltip.tsx';
import { 
    addPricingAgreement,
    getCustomerMetrics,
    getPricingAgreementsForCustomer,
    updatePricingAgreement,
    addDeliveryAddress,
    updateDeliveryAddress,
    updateCustomerInvoiceMandatory,
    resetPassword
    } 
    from '../../api/crmAPI.ts';
import './CRM.css';
import '../components.css';

const CRMOverview = () => {
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    // const [price, setPrice] = useState('');
    // const [editingProductId, setEditingProductId] = useState(null);
    const [selectedCustomerData, setSelectedCustomerData] = useState(null);
    const [selectedCustomerPricingAgreements, setSelectedCustomerPricingAgreements] = useState([]);
    const [deliveryAddresses, setDeliveryAddresses] = useState([]);
    const [newDeliveryAddress, setNewDeliveryAddress] = useState({
        City: "",
        ZipCode: "",
        StreetName: "",
        HouseNumber: "",
    });
    const [editingAddressId, setEditingAddressId] = useState(null);
    const [editDeliveryAddress, setEditDeliveryAddress] = useState({
        City: "",
        ZipCode: "",
        StreetName: "",
        HouseNumber: "",
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState('');
    const [customerSearchInput, setCustomerSearchInput] = useState('');
    const [customerCurrentPage, setCustomerCurrentPage] = useState(1);
    const [customersPerPage, setCustomersPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [isResettingPassword, setIsResettingPassword] = useState(false);
    const [isAddingAddress, setIsAddingAddress] = useState(false);
    const [isChangingPrice, setIsChangingPrice] = useState(false);
    const [isUpdatingMandatory, setIsUpdatingMandatory] = useState(false);

    useEffect(() => {
        refreshCustomers();
    }, []);

    const refreshCustomers = () => {
        setIsLoading(true);
        getCustomerMetrics()
            .then(response => {
                setCustomers(response.data.customers);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching customers:', error);
                setIsLoading(false);
            });
    };

    // Pagination and search logic for product list
    const filteredItems = selectedCustomerPricingAgreements.filter((agreement) => 
        agreement.ProductName.toLowerCase().includes(searchInput.toLowerCase())
    );

    const totalPages = Math.ceil(filteredItems.length / entriesPerPage);
    const indexOfLastItem = currentPage * entriesPerPage;
    const indexOfFirstItem = indexOfLastItem - entriesPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const goToNextPage = () => setCurrentPage((page) => Math.min(page + 1, totalPages));
    const goToPreviousPage = () => setCurrentPage((page) => Math.max(page - 1, 1));

    // Pagination and search logic for customer list
    const filteredCustomers = customers.filter((customer) =>
        customer.CustomerName.toLowerCase().includes(customerSearchInput.toLowerCase())
    );

    const totalCustomerPages = Math.ceil(filteredCustomers.length / customersPerPage);
    const indexOfLastCustomer = customerCurrentPage * customersPerPage;
    const indexOfFirstCustomer = indexOfLastCustomer - customersPerPage
    const currentCustomers = filteredCustomers.slice(indexOfFirstCustomer, indexOfLastCustomer);

    const goToNextCustomerPage = () => setCustomerCurrentPage((page) => Math.min(page + 1, totalCustomerPages));
    const goToPreviousCustomerPage = () => setCustomerCurrentPage((page) => Math.max(page -1, 1));

    useEffect(() => {
        // Fetch the list of customers when the component mounts
        getCustomerMetrics()
            .then(response => {
                setCustomers(response.data.customers);
            })
            .catch(error => {
                console.error('Error fetching customers:', error);
            });
    }, []);

    useEffect(() => {
        setCurrentPage(1); // Reset to first page when search input changes for a product
    }, [searchInput]);

    useEffect(() => {
        setCustomerCurrentPage(1); // Reset to first page when search input changes for a customer
    }, [customerSearchInput]);

    const handleCustomerSelect = async (customerName) => {
        setSelectedCustomer(customerName);

        try {
            const response = await getPricingAgreementsForCustomer(customerName);
            setSelectedCustomerData(response.data);
            setSelectedCustomerPricingAgreements(response.data.PricingAgreements);
        } catch (error) {
            console.error('Error fetching pricing agreements:', error);
            setSelectedCustomerData(null);
            setSelectedCustomerPricingAgreements([]);
        }
    };

    const handleAddDeliveryAddress = async () => {
        setIsAddingAddress(true);
        try {
            // Make API call to add the new deliver address to the database
            await addDeliveryAddress({
                CustomerName: selectedCustomer,
                ...newDeliveryAddress,
            });

            // After adding the new address, fetch the updated list of delivery addresses
            const response = await getPricingAgreementsForCustomer(selectedCustomer);

            setSelectedCustomerData(response.data);

            // Clear the form for the next entry
            setNewDeliveryAddress({
                City: "",
                ZipCode: "",
                StreetName: "",
                HouseNumber: "",
            });
        } catch (error) {
            console.error("error adding delivery address:", error);
        } finally {
            setIsAddingAddress(false);
        }
    };

    const handleEditAddressSelect = (address) => {
        setEditingAddressId(address.AddressID);
        setEditDeliveryAddress({
            City: address.City,
            ZipCode: address.ZipCode,
            StreetName: address.StreetName,
            HouseNumber: address.HouseNumber,
        });
    };

    const handleUpdateDeliveryAddress = async () => {
        try {
            await updateDeliveryAddress(editingAddressId, editDeliveryAddress);
            // After updating the address, fetch the updated customer data
            const response = await getPricingAgreementsForCustomer(selectedCustomer);
            setSelectedCustomerData(response.data);
            // Reset editing state
            setEditingAddressId(null);
            setEditDeliveryAddress({
                City: "",
                ZipCode: "",
                StreetName: "",
                HouseNumber: "",
            });
        } catch (error) {
            console.error("Error updating delivery address:", error);
        }
    };

    const handlePriceChange = async (productID, isChecked) => {
        const newPrice = isChecked ? 1 : 0; // 1 = visible, 0 = not visible
        setIsChangingPrice(true);

        try {

            // Check if the pricing agreement already exists
            const existingPricingAgreementIndex = selectedCustomerPricingAgreements.findIndex(
                (agreement) => agreement.ProductID === productID
            );

            if (existingPricingAgreementIndex !== -1 && selectedCustomerPricingAgreements[existingPricingAgreementIndex].Price !== null) {
                // Pricing agreement exists, update it
                await updatePricingAgreement(selectedCustomer, productID, { Price: newPrice });
            } else {
                // Pricing agreement doesn't exist, add it
                await addPricingAgreement({
                    CustomerName: selectedCustomer,
                    ProductID: productID,
                    Price: newPrice
                });
            }

            // Update the state to reflect the change
            setSelectedCustomerPricingAgreements(prevAgreements => 
                prevAgreements.map(agreement => 
                    agreement.ProductID === productID ? { ...agreement, Price: newPrice } : agreement
                )
            );
        } catch (error) {
            console.error("Error updating pricing agreement:", error);
        } finally {
            setIsChangingPrice(false);
        }
    };

    const handleInvoiceMandatoryChange = async (customerName, isChecked) => {
        setIsUpdatingMandatory(true);
        try {
            const payload = { CustomerInvoiceMandatory: isChecked };
            await updateCustomerInvoiceMandatory(customerName, payload);
            setSelectedCustomerData(prevData => ({ ...prevData, CustomerInvoiceMandatory: isChecked ? 1 : 0 }));
        } catch (error) {
            console.error("Error updating customer invoice mandatory:", error);
        } finally {
            setIsUpdatingMandatory(false);
        }
    };

    const handleResetPassword = async () => {
        if (!selectedCustomerData || !selectedCustomerData.CustomerName || !selectedCustomerData.EmailAddress) {
            return;
        }

        const confirmReset = window.confirm(
            `Weet je zeker dat je het wachtwoord van ${selectedCustomerData.CustomerName} wilt resetten?` +
            `${selectedCustomerData.CustomerName} ontvangt een e-mail met een nieuw wachtwoord op ${selectedCustomerData.EmailAddress}.`);
        if (!confirmReset) {
            return;
        }

        const data = {
            customerName: selectedCustomerData.CustomerName,
            emailAddress: selectedCustomerData.EmailAddress,
        };

        setIsResettingPassword(true);

        try {
            await resetPassword(data);
        } catch (error) {
            console.error("Error resetting password:", error);
        } finally {
            setIsResettingPassword(false);
        }
    }

    return (
        <div>
            <AddCustomerForm refreshCustomers={refreshCustomers} customers={customers} />
            <h2>Klant info</h2>
            <div>
            <Tooltip text="Hier kun je een klant zoeken en selecteren. Als je een klant selecteert kun je de volgende acties uitvoeren:
                - Reset het wachtwoord van de klant
                - Bezorgadressen beheren
                - Producten koppelen aan het bestelportaal de klant"/>
                <input
                    type='text'
                    value={customerSearchInput}
                    onChange={(e) => setCustomerSearchInput(e.target.value)}
                    placeholder='Zoek een klant...'
                />
                {isLoading ? (
                    <div className='spinner'></div>
                ) : (
                    <>
                        <table>
                            <thead>
                                <tr>
                                    <th>Klantnaam</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentCustomers?.map((customer, index) => (
                                    <tr key={index}>
                                        <td>{customer.CustomerName}</td>
                                        <td>
                                            <button onClick={() => handleCustomerSelect(customer.CustomerName)}>
                                                Selecteer
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div>
                            <button onClick={goToPreviousCustomerPage} disabled={customerCurrentPage <= 1}>Vorige</button>
                            <button onClick={goToNextCustomerPage} disabled={customerCurrentPage >= totalCustomerPages}>Volgende</button>
                        </div>
                    </>
                )}
            </div>

            {selectedCustomerData && (
                <div>
                    <h3>Geselecteerde klant: {selectedCustomerData.CustomerName}</h3>
                    <h4>Contactgegevens:</h4>
                    <p>Emailadres: {selectedCustomerData.EmailAddress}</p>
                    <p>Telefoonnummer: {selectedCustomerData.PhoneNumber}</p>
                    <button onClick={handleResetPassword} disabled={isResettingPassword}>
                        {isResettingPassword ? (
                            <div>
                                <div className="spinner"></div> Resetting...
                            </div>
                        ) : "Reset wachtwoord"}
                    </button>
                    <DeliveryAddressManager
                        selectedCustomerData={selectedCustomerData}
                        handleUpdateDeliveryAddress={handleUpdateDeliveryAddress}
                        setEditingAddressId={setEditingAddressId}
                        setEditDeliveryAddress={setEditDeliveryAddress}
                        editingAddressId={editingAddressId}
                        editDeliveryAddress={editDeliveryAddress}
                        handleEditAddressSelect={handleEditAddressSelect}
                        handleAddDeliveryAddress={handleAddDeliveryAddress}
                        newDeliveryAddress={newDeliveryAddress}
                        setNewDeliveryAddress={setNewDeliveryAddress}
                        setSelectedCustomerData={setSelectedCustomerData}
                        isAddingAddress={isAddingAddress}
                    />
                    <h3>Portaalmanagement</h3>
                    <Tooltip text="Hieronder kun je producten koppelen aan de klant. Als een product is gekoppeld aan de klant, is het zichtbaar in het bestelportaal van de klant.
                            Het koppelen doe je door het vakje aan te vinken. Als je het vakje uitvinkt, is het product niet meer zichtbaar in het bestelportaal van de klant.
                            Wil je dat de klant verplicht een inkoopordernummer invult bij het bestellen van producten? Vink dan het vakje 'Inkoopordernummer invullen verplicht' aan."/>
                    <h4>Te bestellen producten:</h4>
                    <input
                        type='text'
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        placeholder='Zoeken...'
                    />
                    <table>
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Zichtbaar in portaal</th>
                                <th>Inkoopordernummer invullen verplicht</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems?.map((item, index) => (
                                <tr key={item.ProductID}>
                                    <td>{item.ProductName}</td>
                                    <td>
                                        {isChangingPrice ? (
                                            <div className="spinner"></div>
                                        ) : (
                                            <input
                                                type='checkbox'
                                                checked={item.Price > 0}
                                                onChange={(e) => handlePriceChange(item.ProductID, e.target.checked)}
                                                disabled={isChangingPrice}
                                            />
                                        )}
                                    </td>
                                    <td>
                                        {index === 0 && (
                                            isUpdatingMandatory ? (
                                                <div className="spinner"></div> // Show spinner for the first row only
                                            ) : (
                                                <input
                                                    type='checkbox'
                                                    checked={selectedCustomerData.CustomerInvoiceMandatory === 1}
                                                    onChange={(e) => handleInvoiceMandatoryChange(selectedCustomerData.CustomerName, e.target.checked)}
                                                    disabled={isUpdatingMandatory}
                                                />
                                            )
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div>
                        <button onClick={goToPreviousPage} disabled={currentPage <= 1}>Vorige</button>
                        <button onClick={goToNextPage} disabled={currentPage >= totalPages}>Volgende</button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CRMOverview