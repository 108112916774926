import React, { useEffect, useState } from "react";
import { 
    getInventoryManagementProductsAndStockMetrics,
    InventoryManagementUpdateProductFields,
    InventoryManagementNewProduct,
    InventoryManagementDeleteProduct
} from "../../api/inventoryManagementAPI.ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "../shared/Tooltip.tsx";
import "../components.css";

const Overview = () => {
    const [metrics, setMetrics] = useState(null);
    const [editingProductId, setEditingProductId] = useState(null);
    const [updates, setUpdates] = useState({});
    const [loading, setLoading] = useState(false);
    const [addingProduct, setAddingProduct] = useState(false);
    const [updatingProduct, setUpdatingProduct] = useState(false);
    const [productName, setProductName] = useState("");
    const [Description, setDescription] = useState("");

    useEffect(() => {
        const fetchInventoryMetrics = async () => {
            setLoading(true);
            try {
                const response = await getInventoryManagementProductsAndStockMetrics();
                setMetrics(response.data);
            } catch (error) {
                console.error('Error fetching inventory metrics:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchInventoryMetrics();
    }, []);

    const handleEditRow = (productId) => {
        //Find the product to update based on ProductID
        const productToUpdate = metrics?.inventory.find(item => item.ProductID === productId);
        setEditingProductId(productId); // Store the ProductID being edited
        setUpdates(productToUpdate);
    };

    const handleInputChange = (event) => {
        // Update the updates state with the new field value
        const { name, value } = event.target;
        setUpdates((prevUpdates) => ({ ...prevUpdates, [name]: value }));
    };

    const handleUpdateRow = async () => {
        setUpdatingProduct(true);
        try {
            // Make a PATCH request to update the product fields
            await InventoryManagementUpdateProductFields(editingProductId, updates);

            // Refetch inventory metrics to get the updated data
            const response = await getInventoryManagementProductsAndStockMetrics();
            setMetrics(response.data);

            // Clear the editing state
            setEditingProductId(null);
            setUpdates({});
        } catch (error) {
            console.error("Error updating product fields:", error);
        } finally {
            setUpdatingProduct(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAddingProduct(true);
        try {
            await InventoryManagementNewProduct({
                ProductName: productName,
                Description: Description,
            });

            // Fetch the lastest inventory metrics including the new product
            const response = await getInventoryManagementProductsAndStockMetrics();
            setMetrics(response.data);

            //Clear form fields
            setProductName("");
            setDescription("");
        } catch (error) {
            console.error("Error adding product:", error);
        } finally {
            setAddingProduct(false);
        }
    };

    const handleDeleteProduct = async (productId) => {
        try {
            await InventoryManagementDeleteProduct(productId);

            //Refetch inventory metrics to get the updated data
            const response = await getInventoryManagementProductsAndStockMetrics();
            setMetrics(response.data);
        } catch (error) {
            console.error("Error deleting product:", error);
        }
    };

    return (
        <div>
            <h2>Overzicht</h2>
            <Tooltip text="Dit is het productoverzicht. Hierin staan alle producten die in het bestelportaal gekoppeld kunnen worden"/>
            {loading ? (
                <div>
                    <div className="spinner"></div> Laden...
                </div>
                    ) : (
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>Product</th>
                            <th>Artikelnummer</th>
                            <th>Aanpassen</th>
                            <th>
                                <Tooltip text="Met het pennetje kun je het artikelnummer aanpassen"/>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {metrics?.inventory.map((item, index) => (
                            <tr key={index}>
                                <td>{item.ProductName}</td>
                                <td>
                                    {editingProductId === item.ProductID ? (
                                        <input
                                            type="text"
                                            name="ProductNumber"
                                            value={updates.ProductNumber || item.ProductNumber}
                                            onChange={handleInputChange}
                                            maxLength={255}
                                        />
                                    ) : (
                                        item.ProductNumber
                                    )}
                                </td>
                                <td>
                                    {editingProductId === item.ProductID ? (
                                        <button onClick={handleUpdateRow} disabled={updatingProduct}>
                                            {updatingProduct ? (
                                                <div>
                                                    <div className="spinner"></div> Opslaan...
                                                </div>
                                            ) : "Opslaan"}
                                        </button>
                                    ) : (
                                        <div>
                                            <FontAwesomeIcon icon={faPen} onClick={() => handleEditRow(item.ProductID)} />
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )}
            <div>
                <h2>Product toevoegen</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                    <label>
                        Productnaam:
                        <input
                            type="text"
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                            maxLength={255}
                            />
                    </label>
                    </div>
                    <div>
                    <label>
                        Artikelnummer:
                        <input
                            type="text"
                            value={Description}
                            onChange={(e) => setDescription(e.target.value)}
                            maxLength={255}
                            />
                    </label>
                    </div>
                    <button type="submit" disabled={addingProduct}>
                        {addingProduct ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className="spinner" style={{ marginRight: 5 }}></div> Toevoegen...
                            </div>
                        ) : "Toevoegen"}
                    </button>
                    <Tooltip text="Hier kun je een nieuw product toevoegen. 
                            Je hoeft alleen een productnaam en artikelnummer in te vullen.
                            het artikelnummer kun je achteraf nog aanpassen."
                    />
                </form>
            </div>
        </div>
    );
};

export default Overview;
