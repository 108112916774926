import React, { useState } from 'react';
import { updateIsActiveDeliveryAddress } from '../../api/crmAPI.ts';
import Tooltip from '../shared/Tooltip.tsx';
import { capitalizeWords } from '../shared/utils/capitalizeWords.ts';

const DeliveryAddressManager = ({
    selectedCustomerData,
    handleUpdateDeliveryAddress,
    setEditingAddressId,
    setEditDeliveryAddress,
    editingAddressId,
    editDeliveryAddress,
    handleEditAddressSelect,
    handleAddDeliveryAddress,
    newDeliveryAddress,
    setNewDeliveryAddress,
    setSelectedCustomerData,
    isAddingAddress,
}) => {
    const deliveryAddresses = selectedCustomerData ? selectedCustomerData.DeliveryAddresses : [];
    const [isChecking, setIsChecking] = useState(false);

    const handleIsActiveChange = async (addressID, isChecked) => {
        // check if the change is to deactivate the only active address
        const activeCount = deliveryAddresses.filter(addr => addr.IsActive === 'TRUE').length;
        const isCurrentlyActive = deliveryAddresses.find(addr => addr.AddressID === addressID).IsActive === 'TRUE';

        // prevent deactivating the only active address
        if (activeCount === 1 && isCurrentlyActive && !isChecked) {
            alert('Er moet minstens één actief bezorgadres zijn.');
            return;
        }

        setIsChecking(true);
        
        try {
            const payload = { IsActive: isChecked }; // Toggle the current state
            await updateIsActiveDeliveryAddress(addressID, payload);
            // Update the local state to reflect the change immediately in the UI
            setSelectedCustomerData(prevData => ({
                ...prevData,
                DeliveryAddresses: prevData.DeliveryAddresses.map(addr => 
                    addr.AddressID === addressID ? { ...addr, IsActive: isChecked ? 'TRUE' : 'FALSE' } : addr
                )
            }));
        } catch (error) {
            console.error("Error updating active status:", error);
        } finally {
            setIsChecking(false);
        }
    };

    return (
        <div>
            <h2>
                {deliveryAddresses.length > 1 ? "Bezorgadressen:" : "Bezorgadres"}
            </h2>
            <Tooltip text="Hier staan de bezorgadressen van de geselecteerde klant. Er moet minstens één actief bezorgadres zijn.
                    Wordt een bezorgadres niet meer gebruikt? Zet deze dan op inactief door het vinkje uit te zetten. Alleen de actieve bezorgadressen
                    zijn zichtbaar in het bestelportaal." />
                <div className='deliveryaddress-info'>
                    <table>
                        <thead>
                            <tr>
                                <th>Straatnaam</th>
                                <th>Huisnummer</th>
                                <th>Stad</th>
                                <th>Postcode</th>
                                <th>Actief</th>
                            </tr>
                        </thead>
                        <tbody>
                            {deliveryAddresses?.map((address, index) => (
                                <tr key={index}>
                                    <td>{address.StreetName}</td>
                                    <td>{address.HouseNumber}</td>
                                    <td>{address.City}</td>
                                    <td>{address.ZipCode}</td>
                                    <td>
                                        {isChecking ? (
                                            <div className="spinner"></div> // Show spinner while loading
                                        ) : (
                                            <input
                                                type='checkbox'
                                                checked={address.IsActive === 'TRUE'}
                                                onChange={(e) => handleIsActiveChange(address.AddressID, e.target.checked)}
                                            />
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            <h2>Bezorgadres toevoegen:</h2>
            <Tooltip text="Hier kun je een nieuw bezorgadres toevoegen. Het nieuwe adres heeft standaard de status actief." />
            <div>
                <label>
                    Straatnaam:
                    <input
                        type='text'
                        value={newDeliveryAddress.StreetName}
                        onChange={(e) =>
                            setNewDeliveryAddress({
                                ...newDeliveryAddress,
                                StreetName: capitalizeWords(e.target.value),
                            })
                        }
                        maxLength={255}
                    />
                </label>
                <label>
                    Huisnummer:
                    <input
                        type='text'
                        value={newDeliveryAddress.HouseNumber}
                        onChange={(e) =>
                            setNewDeliveryAddress({
                                ...newDeliveryAddress,
                                HouseNumber: e.target.value,
                            })
                        }
                        style={{ width: "40px" }}
                        maxLength={10}
                    />
                </label>
                <label>
                    Stad:
                    <input
                        type='text'
                        value={newDeliveryAddress.City}
                        onChange={(e) =>
                            setNewDeliveryAddress({
                                ...newDeliveryAddress,
                                City: capitalizeWords(e.target.value),
                            })
                        }
                        maxLength={255}
                    />
                </label>
                <label>
                    Postcode:
                    <input
                        type='text'
                        value={newDeliveryAddress.ZipCode}
                        onChange={(e) =>
                            setNewDeliveryAddress({
                                ...newDeliveryAddress,
                                ZipCode: e.target.value,
                            })
                        }
                        maxLength={10}
                    />
                </label>
                <button onClick={() => handleAddDeliveryAddress(newDeliveryAddress)} disabled={isAddingAddress}>
                    {isAddingAddress ? (
                        <span>
                            <div className="spinner"></div> Toevoegen...
                        </span>
                    ) : "Bezorgadres toevoegen"}
                </button>
            </div>
        </div>
    );
};

export default DeliveryAddressManager;
