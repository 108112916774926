import React, {useState, useRef, useEffect, FC} from 'react';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './Tooltip.css'; 

interface TooltipProps {
    text: string;
}

const Tooltip: FC<TooltipProps> = ({ text }) => {
    const [isVisible, setIsVisible] = useState(false);
    const tooltipRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        const handlePosition = () => {
            const tooltip = tooltipRef.current;
            if (tooltip) {
                const rect = tooltip.getBoundingClientRect();

                // horizontal positioning
                if (rect.left < 0) {
                    tooltip.style.left = '0px';
                    tooltip.style.marginLeft = '0px';
                    tooltip.style.transform = 'translateX(0%)';
                } else if (rect.right > window.innerWidth) {
                    tooltip.style.left = 'auto';
                    tooltip.style.right = '0px';
                }

                // vertical positioning
                if (rect.bottom > window.innerHeight) {
                    tooltip.style.bottom = 'auto';
                    tooltip.style.top = '125%';
                }
                if (rect.top < 0) {
                    tooltip.style.top = '125%';
                    tooltip.style.bottom = 'auto';
                }
            }
        };

        if (isVisible) {
            handlePosition();
        }
    }, [isVisible]);

    return (
        <div className="tooltip">
            <FontAwesomeIcon 
                icon={faInfoCircle} 
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)} 
            />
            {isVisible && (
                <span ref={tooltipRef} className="tooltiptext">
                    {text}
                </span>
            )}
        </div>
    );
};

export default Tooltip;

