import React, {useState} from "react";
import { addCustomer, addDeliveryAddress } from "../../api/crmAPI.ts";
import "../components.css";
import Tooltip from "../shared/Tooltip.tsx";
import { capitalizeWords } from "../shared/utils/capitalizeWords.ts";

const AddCustomerForm = ({ refreshCustomers, customers }) => {
    const [customerName, setCustomerName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [streetName, setStreetName] = useState("");
    const [houseNumber, setHouseNumber] = useState("");
    const [city, setCity] = useState("");
    const [addingCustomer, setAddingCustomer] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if all required fields are filled
        if (!customerName || !emailAddress || !city || !zipCode || !streetName || !houseNumber) {
            alert("Alle velden behalve telefoonnummer en toevoeging zijn verplicht");
            return;
        }

        if (!emailAddress.includes('@') || !emailAddress.includes('.')) {
            alert("Voer een geldig e-mailadres in dat een '@' en een punt bevat.");
            return;
        }

        const zipCodeRegex = /^[1-9][0-9]{3} [a-zA-Z]{2}$/;

        // Check if the zip code is in the correct format
        if (!zipCodeRegex.test(zipCode)) {
            alert("Postcode moet 4 cijfers gevolgd door een spatie en 2 letters zijn, bijv. 1234 AB");
            return;
        };

        // Check if the customer name already exists in the loaded customer data
        const existingCustomer = customers?.find(c => c.CustomerName.toLowerCase() === customerName.toLowerCase());
        if (existingCustomer) {
            alert("Er bestaat al een klant met deze naam");
            return;
        }

        setAddingCustomer(true);

        try {
            const customerResponse = await addCustomer({
                CustomerName: customerName,
                EmailAddress: emailAddress,
                PhoneNumber: phoneNumber
            });

            if (customerResponse.data.success) {
                alert("Klant toegevoegd en welkomstmail verstuurd");
            }

            if (customerResponse.data.message === "Customer added successfully") {
                await addDeliveryAddress({
                    CustomerName: customerName,
                    City: city,
                    ZipCode: zipCode,
                    StreetName: streetName,
                    HouseNumber: houseNumber,
                });
            }

            //Clear form fields
            setCustomerName("");
            setEmailAddress("");
            setZipCode("");
            setStreetName("");
            setHouseNumber("");
            setCity("");
            setPhoneNumber("");
            refreshCustomers();
        } catch (error) {
            console.error("Error adding customer:", error);
        } finally {
            setAddingCustomer(false);
        }
    };

    return (
        <div>
        <h2>Klant toevoegen</h2>
        <form onSubmit={handleSubmit}>
            <div>
                <label>
                    Klantnaam:
                    <input
                        type="text"
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        maxLength={255}
                        />
                </label>
            </div>
            <div>
                <p>Bezorgadres:</p>
                <div className="address-container">
                    <div>
                        <label>
                            Straatnaam:
                            <input 
                                type="text"
                                value={streetName}
                                onChange={(e) => setStreetName(capitalizeWords(e.target.value))} 
                                maxLength={255}
                                />
                        </label>
                        <label>
                            Huisnummer:
                            <input 
                                type="text"
                                value={houseNumber}
                                onChange={(e) => setHouseNumber(e.target.value)} 
                                style={{ width: '40px' }} 
                                maxLength={10}
                                />
                        </label>
                    </div>
                    <div>
                        <label>
                            Postcode:
                            <input 
                                type="text" 
                                value={zipCode} 
                                onChange={(e) => setZipCode(e.target.value)} 
                                maxLength={7}
                                placeholder="1234 AB"
                                />
                        </label>
                        <label>
                            Stad:
                            <input 
                                type="text"
                                value={city}
                                onChange={(e) => setCity(capitalizeWords(e.target.value))} 
                                maxLength={255}
                            />
                        </label>
                    </div>
                </div>
            </div>
            <div>
                <p>Contactgegevens:</p>
                <label>
                    Telefoonnummer:
                    <input 
                        type="number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        maxLength={10}
                        />
                </label>
            </div>
            <div>
                <label>
                    Emailadres:
                    <input 
                        type="text"
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        maxLength={255}
                        />
                </label>
            </div>
            <button type="submit" disabled={addingCustomer}>
                {addingCustomer ? (
                    <div>
                        <div className="spinner"></div> Klant toevoegen...
                    </div>
                ) : "Klant toevoegen"}
            </button>
            <Tooltip text="Met deze button kun je een nieuwe klant toevoegen. Alle velden behalve telefoonnummer zijn verplicht.
                    zodra je op klant toevoegen klikt wordt een account voor de klant aangemaakt en een welkomstmail verstuurd. De welkomstmail
                    bevat een automatisch gegenereerd wachtwoord waarmee de klant kan inloggen op het bestelportaal. De klant kan pas daadwerkelijk
                    producten bestellen zodra je ze hebt gekoppeld hieronder."
            />
        </form>
    </div>
    )
    };

export default AddCustomerForm;