import axios from "axios";
import apiConfig from './apiConfig.json';
import axiosRetry from 'axios-retry';
import type { RetryCondition } from './types'; 

const baseURL = process.env.REACT_APP_API_BASE_URL || apiConfig.API_BASE_URL;

const apiServiceAWS = axios.create({
    baseURL: baseURL,
    auth: {
        username: 'LHapi',
        password: 'LH4545!API'
    }
});

const retryCondition: RetryCondition = (error) => {
    if (error.config?.method === 'get' &&
        (axiosRetry.isNetworkOrIdempotentRequestError(error) ||
            (error.response && error.response.status !== 200))) {
        return true;
    }
    return false;
}

// Add retry logic to apiServiceAWS for GET requests only
axiosRetry(apiServiceAWS, {
    retries: 3,
    retryCondition: retryCondition,
    retryDelay: () => 1000,
    onRetry: (retryCount, error, requestConfig) => {
        console.log(`Retry attempt #${retryCount} for GET request to ${requestConfig.url}`);
        if (error.response) {
            console.log(`Status Code: ${error.response.status}`);
        } else {
            console.log(`Error message: ${error.message}`);
        }
    }
});


export { apiServiceAWS };