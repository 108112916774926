import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import styled from 'styled-components';
import Home from "./components/homePage";
import InventoryOverview from "./components/inventoryManagement/inventoryManagementProductsAndStock";
import CRMOverview from "./components/CRM/CRMCustomers"
import OpenOrders from "./components/sales/orders/orders";
import SearchOrders from "./components/sales/orders/searchOrders";
import InsertOrder from "./components/sales/orders/InsertOrder/insertOrder";
import companyLogo from './logo.png';
import { authenticateUser } from "./api/inventory-management-appAPI.ts";
import './App.css';

const SESSION_TIMEOUT_DURATION = 1000 * 60 * 60 // 1 hour


// Styled components
const AppWrapper = styled.div`
  display:flex;
  `;
const Navbar = styled.nav`
  background-color: black;
  color: #CCCCCC;
  padding: 15px;
  width: 200px;
  display: flex;
  flex-direction: column;
  a {
    color: #CCCCCC;
  }ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

`;

const LogoImage = styled.img`
  max-width: 50%;
  hight: auto;
  margin-bttom: 10px;
  `;

const LoginForm = ({ handleLogin, setUserName, setPassword, errorMessage, isLoggingIn }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin();
  };

  return (
  <form onSubmit={handleSubmit}>
    <h2>Inloggen</h2>
    <input
      type="text"
      placeholder="Gebruikersnaam"
      onChange={(e) => setUserName(e.target.value)}
    />
    <br />
    <input
      type="password"
      placeholder="Wachtwoord"
      onChange={(e) => setPassword(e.target.value)}
    />
    <br />
    <button type='submit' disabled={isLoggingIn}>
        {isLoggingIn ? (
            <div className="spinner-container">
                <div className="spinner"></div> Inloggen...
            </div>
        ) : "Inloggen"}
    </button>
    <p>{errorMessage}</p>
  </form>
);
};

// const Content = styled.div`
//   flex: 1;
//   background-color: #333333;
//   color: #CCCCCC;
//   padding: 20px;
//   `;

const Footer = styled.footer`
  background-color: black;
  color: green;
  padding: 20px;
`;

const NavItem = styled.li`
  position: relative;
  transition: transform 0.3s ease;
  ul {
    display: ${({ open}) => (open ? "block" : "none")};
    top: 100%;
    left: 0;
    background-color: #333333;
    padding: 0;
    margin: 0;
  }
`;

const DropDownItem = styled.li`
  list-style: none;
  padding: 10px;
`;

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isInventoryOpen, setIsInventoryOpen] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const toggleInventoryMenu = () => {
    setIsInventoryOpen(!isInventoryOpen);
  };
  
  const [isCRMopen, setIsCRMOpen] = useState(false);

  const toggleCRMmenu = () => {
    setIsCRMOpen(!isCRMopen);
  };

  const [isSalesOpen, setIsSalesOpen] = useState(false);

  const toggleSalesMenu = () => {
    setIsSalesOpen(!isSalesOpen);
  };

  const handleLogin = async () => {
    setIsLoggingIn(true);
    try {
      const response = await authenticateUser({
        userName,
        password
      });
      
      if (response.data.message === "Authentication successful") {
        setIsLoggedIn(true);
      } else {
        setErrorMessage("Inloggen mislukt");
      }
    } catch (error) {
      console.error("Login error: ", error);
      setErrorMessage("Inloggen mislukt");
    }
    setIsLoggingIn(false);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setErrorMessage('');
    if (process.env.NODE_ENV === 'development') {
      window.location.href = 'http://localhost:3000';
    } else {
      window.location.href = 'https://lhportaal.nl';
    }
  };

  useEffect(() => {
    document.title = "LH portaalbeheer";
  }
  , []);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    let timeout = setTimeout(handleLogout, SESSION_TIMEOUT_DURATION);

    const resetTimeout = () => {
      clearTimeout(timeout);
      timeout = setTimeout(handleLogout, SESSION_TIMEOUT_DURATION);
    };

    window.addEventListener("mousemove", resetTimeout);
    window.addEventListener("mousedown", resetTimeout);
    window.addEventListener("keypress", resetTimeout);
    window.addEventListener("scroll", resetTimeout);

    return () => {

      window.removeEventListener("mousemove", resetTimeout);
      window.removeEventListener("mousedown", resetTimeout);
      window.removeEventListener("keypress", resetTimeout);
      window.removeEventListener("scroll", resetTimeout);
      clearTimeout(timeout);
    };

  }, [isLoggedIn]);

  return (
    <div>
      {!isLoggedIn && (
        <div>
          <LogoImage src={companyLogo} alt="Company Logo"/>
          <LoginForm
            handleLogin={handleLogin}
            setUserName={setUserName}
            setPassword={setPassword}
            errorMessage={errorMessage}
            isLoggingIn={isLoggingIn}
          />
        </div>
      )}
      {isLoggedIn && (
      <Router>
        <button onClick={handleLogout}>Uitloggen</button>
      <AppWrapper>
      <Navbar>
      <LogoImage src={companyLogo} alt="Company Logo"/>
        <ul>
          <NavItem>
          <li><Link to="/">Home</Link></li>
          </NavItem>
          <NavItem onClick={toggleInventoryMenu} open={isInventoryOpen}>
            <span style={{cursor: 'pointer'}}>Producten</span>
            <ul>
              <DropDownItem>
                <Link to="/inventorymanagement/productsandstock">Productenlijst</Link>
              </DropDownItem>
            </ul>
          </NavItem>
          <NavItem onClick={toggleCRMmenu} open={isCRMopen}>
            <span style={{cursor: 'pointer'}}>Klanten</span>
            <ul>
              <DropDownItem>
                <Link to="/crm/customers">Klantinfo</Link>
              </DropDownItem>
            </ul>
          </NavItem>
          <NavItem onClick={toggleSalesMenu} open={isSalesOpen}>
            <span style={{cursor: 'pointer'}}>Orders</span>
            <ul>
              <DropDownItem>
                <Link to="/sales/orders">Openstaande orders</Link>
              </DropDownItem>
              <DropDownItem>
                <Link to="/sales/searchorders">Orders zoeken</Link>
              </DropDownItem>
              <DropDownItem>
                <Link to="sales/insertorder">Order invoeren</Link>
              </DropDownItem>
            </ul>
          </NavItem>
          {/* <li><Link to="/about">About</Link></li> */}
        </ul>
      </Navbar>
      {/* <Content> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/crm/customers" element={<CRMOverview />} />
        <Route path="/inventorymanagement/productsandstock" element={<InventoryOverview />} />
        <Route path="/sales/orders" element={<OpenOrders />} />
        <Route path="/sales/searchorders" element={<SearchOrders />} />
        <Route path="/sales/insertorder" element={<InsertOrder />} />
      </Routes>
      {/* </Content> */}
      </AppWrapper>
      <Footer>
        <p>© 2023 Faaij Automation. All rights reserved.</p>
      </Footer>
    </Router>
      )}
    
    </div>
  );
};

export default App;