import{ apiServiceAWS } from './apiService';
import { OrderID, SearchOrdersParams, SubmitOrderData, SubmitSaleData, UpdateActualDeliveryDate } from './types';

// // not in use
// export const addSale = (data) => {
//     return apiServiceAWS.post('/sales/add', data);
// };

// // not in use
// export const submitRecurringOrder = (data) => {
//     return apiServiceAWS.post('/sales/submitrecurringorder', data);
// };

// // not in use
// export const getSales = () => {
//     return apiServiceAWS.get('/salesdashboard/getsales');
// };

export const getNewOrders = () => {
    return apiServiceAWS.get('/orders/getneworders');
};

export const updateActualDeliveryDate = (orderID: OrderID, data: UpdateActualDeliveryDate) => {
    return apiServiceAWS.put(`/orders/setactualdeliverydate/${orderID}`, data);
};

export const confirmOrder = (orderID: OrderID) => {
    return apiServiceAWS.put(`/orders/confirmorder/${orderID}`);
};

export const cancelOrder = (orderID: OrderID) => {
    return apiServiceAWS.put(`/orders/cancelorder/${orderID}`);
};

export const submitSale = (data: SubmitSaleData) => {
    return apiServiceAWS.post('orders/submitsale', data);
};

export const searchOrders = (params: SearchOrdersParams) => {
    return apiServiceAWS.get('/orders/searchorders', {params});
};

export const submitOrder = (data: SubmitOrderData) => {
    return apiServiceAWS.post('/salesportal/submitorder', data);
};

export const getLastInvoice = () => {
    return apiServiceAWS.get('/salesportal/getlastinvoice');
};